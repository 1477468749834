<template>
	<section class="cast-detail-container">
		<div class="cast-info-row">
			<div class="cast-avatar" v-if="castDetail && castDetail.profile_path">
				<img :src="`${$store.state.home.base_url}${$store.state.home.poster_size[3]}${castDetail.profile_path}`" alt="" />
			</div>
			<h2 class="cast-carreir-title">{{ castDetail.name }}</h2>

			<div class="cast-sub-title personal">Personal Info</div>

			<div class="cast-personal-info-row">
				<div class="cast-personal-info-detail-row" v-if="castDetail">
					<div class="cast-personal-info-detail-item" v-if="castDetail && castDetail.known_for_department">
						<strong><bdi>Known For</bdi></strong> {{ castDetail.known_for_department }}
					</div>
					<div class="cast-personal-info-detail-item">
						<strong><bdi>Gender</bdi></strong> {{ castDetail.gender == 2?'Male':'Female' }}
					</div>
					<div class="cast-personal-info-detail-item">
						<strong><bdi>Birthday</bdi></strong>
						{{ castDetail.birthday }}
					</div>
					<div class="cast-personal-info-detail-item">
						<strong><bdi>Place of Birth</bdi></strong> {{ castDetail.place_of_birth }}
					</div>
				</div>
				<div class="cast-personal-info-detail-item">
					<strong><bdi>Also Known As</bdi></strong>
					<ul class="cast-personal-info-detail-item-ul">
						<li itemprop="additionalName" v-for="(data,i) in castDetail.also_known_as" :key="i">{{data}}</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="cast-carreir-container flex-col">
			<div class="biograpy-row flex-col">
				<div class="cast-sub-title biograpy">Biography</div>
				<div class="biograpy-content" v-html="castDetail.biography"></div>
			</div>

			<div class="known-for-row">
				<div class="white-shadow"></div>
				<div class="cast-sub-title known">Known For</div>
				<div class="known-for-content-row" v-if="castCredit && castCredit.cast">
					<div class="known-for-content-item flex-col just-start" v-for="(poster,j) in castCredit.cast" :key="`cast_${j}`">
						<img v-if="poster.poster_path" :src="`${$store.state.home.base_url}${$store.state.home.poster_size[3]}${poster.poster_path}`" class="known-for-poster" alt="" />
						<div v-if="poster.poster_path" class="known-for-name">{{poster.original_title}}</div>
					</div>
				</div>
			</div>

			<div class="cast-career-introduction-row acting">
				<div class="cast-sub-title career-introduction">Acting</div>
				<div class="cast-career-intro-content-row">
					<table class="credits" border="0">
						<tbody v-if="castCredit && castCredit.cast">
							<tr v-for="(knowns,u) in castCredit.cast" :key="`credits_${u}`">
								<td>
									<table class="credit_group">
										<tbody>
											<tr>
												<td class="year">{{ knowns.release_date }}</td>
												<td class="seperator"><span class="circle-empty"></span></td>
												<td class="account_adult_false">
													<bdi>{{knowns.title}}</bdi>
													<span class="group"> as <span class="character">{{knowns.character}}</span> </span>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import "@/css/home/mobile/cast-detail.scss";
import { mapState } from "vuex";

export default {
	data() {
		return {
			backgroundImage: "",
			castDetail: null,
			castCredit: null,
		};
	},
	created() {
		console.log(this.$route.query);
		if (this.$store.state.homedata == "success") {
			this.getDetail(this.$route.query.cast_id);
			this.getCredit(this.$route.query.cast_id);
		}
		this.$store.watch(
			(state, getters) => state.homedata,
			(newVal, oldVal) => {
				// 在这里处理状态变化
				console.log("targetValue 已经设置为", newVal);
				this.getDetail(this.$route.query.cast_id);
				this.getCredit(this.$route.query.cast_id);
			}
		);
	},
	computed: {
		...mapState({
			targetValue: (state) => state.homedata,
		}),
	},
	mounted() {
		this.backgroundImage = `url('https://www.themoviedb.org/t/p/w440_and_h660_face/ekZobS8isE6mA53RAiGDG93hBxL.jpg')`;
	},
	methods: {
		getDetail(person_id) {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(`https://api.themoviedb.org/3/person/${person_id}`, options)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.castDetail = response;
				})
				.catch((err) => console.error(err));
		},
		getCredit(person_id) {
			const options = {
				method: "GET",
				headers: {
					accept: "application/json",
					Authorization:
						"Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIyYzMzNjE2YTRjODNiN2Y2OTgyNDI3Njg5NjhhMGY3MyIsInN1YiI6IjY0NjRhYTUwOWYzN2IwMDE1Y2MzODdlYyIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ._XwSWCopAiYWATtv8Vl5w7KyEBJ25mpQf_bnd4Dyhpg",
				},
			};

			fetch(
				`https://api.themoviedb.org/3/person/${person_id}/combined_credits`,
				options
			)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					this.castCredit = response;
				})
				.catch((err) => console.error(err));
		},
	},
};
</script>

